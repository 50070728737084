import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import shuffle from 'lodash/shuffle'
import { graphql, useStaticQuery } from 'gatsby'

import Image from 'components/Image'
import { COLOR } from 'styles/tokens'
import { H, Grid, Wrapper } from 'components/common'
import SuccessHeading from 'components/SuccessHeading'

import Layout from 'components/layout'
import SEO from 'components/seo'
import FeaturedJobs from 'components/FeaturedJobs'

const StyledWrapper = styled(Wrapper)`
  background-color: ${COLOR.GREY.DARK};
  color: ${COLOR.WHITE};
`

const Submitted = ({ pageContext: { featuredJobs } }) => {
  const { image } = useStaticQuery(graphql`
    query {
      image: file(
        relativePath: { eq: "woman-in-yellow-hat-crossing-fingers.jpg" }
      ) {
        ...fluidImage
      }
    }
  `)
  return (
    <Layout>
      <SEO title="Successful application" />
      <StyledWrapper>
        <Wrapper container centered>
          <SuccessHeading color={COLOR.WHITE}>
            Your application is on it’s way
          </SuccessHeading>
        </Wrapper>
      </StyledWrapper>
      <Grid container>
        <Grid.Item spanFromM={5} style={{ alignSelf: 'center' }}>
          <H size="L">Check your email</H>
          <p>
            You should receive a confirmation email, and one of the Pixel Pond
            team will be in touch soon.
          </p>
          <p>Good luck!</p>
        </Grid.Item>
        <Grid.Item spanFromM={6} startColumnFromM={7}>
          <Image
            sizes={{ ...image.childImageSharp.fluid, aspectRatio: 3 / 2 }}
          />
        </Grid.Item>
      </Grid>
      <FeaturedJobs
        jobs={shuffle(featuredJobs).slice(0, 3)}
        title="Featured jobs"
        background={COLOR.BACKGROUND.PALE}
      />
    </Layout>
  )
}

Submitted.propTypes = {
  pageContext: PropTypes.shape({
    featuredJobs: PropTypes.array,
  }).isRequired,
}

export default Submitted
